import React from 'react';

const Sun = () => (
    <svg role='img' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' height='4.5em' width='4.5em'>
        <title>Sun Icon</title>
        <path fill='#FF8C00' d='M256.01 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.41 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.31 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4L9.6 240.5c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.31 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.41 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.11 49.9-181.01 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z' />
    </svg>
);

export default Sun;
